import { Button } from 'sw-ui';

import { develop } from '../../services/develop';

import styles from './styles.module.scss';

export const Develop = () => (
  <div className={styles.container}>
    <Button onClick={develop.openSwagger}>
      Открыть swagger
    </Button>
  </div>
);
