import { PropsWithChildren } from 'react';

import { LeftSidebar } from '../LeftSidebar';
import { Header } from '../Header';

import styles from './styles.module.scss';

export const BaseContainer = ({ children }: PropsWithChildren) => (
  <div className={styles.wrap}>
    <LeftSidebar />
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        { children }
      </div>
    </div>
  </div>
);
