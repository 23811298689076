import { clsx } from 'clsx';

import styles from './styles.module.scss';

export const stateVariants = {
  true: {
    logoPath: '/static/media/logo.svg',
    arrow: 'keyboard_arrow_left',
    className: styles.containerWidthOpen,
    mailClassName: clsx(styles.userMail, styles.userMailWidth, styles.baseMargins),
  },
  false: {
    logoPath: '/static/media/logo_small.png',
    arrow: 'keyboard_arrow_right',
    className: styles.containerWidthClose,
    mailClassName: styles.userMail,
  },
};
