import {
  action, computed, makeObservable, observable, runInAction,
} from 'mobx';
import { IRegistryConstructorTemplate } from './types.ts';
import { UUID } from 'node:crypto';


export class DocumentTemplates {
  constructor() {
    makeObservable(this);
  }

  @observable accessor templates: IRegistryConstructorTemplate[] = [];
  @observable accessor _loading = false;
  @observable accessor error: string | null = null;
  @observable accessor loadingField: UUID | null = null;

  @action
  setTemplates = (nt: IRegistryConstructorTemplate[]) => {
    this.templates = nt;
  };

  autoClearAsync = async () => {
    await new Promise((resolve) => setTimeout(resolve, 20000));

    runInAction(() => {
      this.error = null;
    });
  };

  @action
  setError = (message: string) => {
    this.error = message;
    this.autoClearAsync();
  };

  @action
  setLoading = (status: boolean) => {
    this._loading = status;
  };

  @action
  setLoadingField = (status: UUID | null) => {
    this.loadingField = status;
  };

  @computed
  get loading() {
    return this._loading;
  }
}

export const documentTemplates = new DocumentTemplates();
