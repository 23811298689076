import Api from '../../../bi/api';

import { workspaceStore } from './store';

import { redirectToOld } from '../../utils/redirect';

export class Workspace {
  store = workspaceStore;

  logout = () => {
    redirectToOld('/login');
    Api.logout();
  };

  kickOutIfUnknown = () => {
    if (Api.currentToken === null) {
      redirectToOld('/login');
      Api.logout();
    }
  };

  getUserName = async () => {
    try {
      const resp = await Api.UserName.getUserName();
      this.store.setUserName(resp);
    } catch (e) {
      return null;
    }

    return null;
  };
}


export const workspace = new Workspace();
