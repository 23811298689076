import { develop } from './develop';
import { company } from './company';
import { workspace } from './workspace';
import { access } from './access';


export const services = {
  develop,
  company,
  workspace,
  access,
};

