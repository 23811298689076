import ApiInstance from '../../../bi/api';
import CONFIG from '../../../config';

export class Develop {
  openSwagger = async () => {
    try {
      await ApiInstance.Swagger.replaceToken();

      window.open(`${CONFIG.API_CLUSTER}/oas-utilities/swagger`, '_blank');

      return null;
    } catch (error) {
      return error;
    }
  };
}

export const develop = new Develop();
