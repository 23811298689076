import { redirectToOld } from '../../../utils/redirect';

import MENUITEMS, { V2_PAGES } from '../../../../app/routers/menuItems';
import CONFIG from '../../../../config';

import { IMenuItem, INewMenuItem } from '../types.ts';

const DEV_PAGES = [
  MENUITEMS.DEV_PAGE,
];

export const filterV2Adopted = (source: IMenuItem[]) => source.filter(({ type }) => !V2_PAGES.includes(type));

export const devFilterMenu = (source: IMenuItem[]) => {
  if (!CONFIG.DEV_PAGES) return source.filter(({ type }) => !DEV_PAGES.includes(type));

  return source;
};

export const remapMenu = (
  source: IMenuItem[],
): INewMenuItem[] => source.map(
  (item) => ({
    name: item.name,
    href: item.url,
    icon: item.icon,
    onRedirect: redirectToOld,
    nested: remapMenu(item?.items || []),
  }),
);
