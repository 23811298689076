import CONFIG from '../../../config';
import { INewMenuItem } from './types.ts';

export const menuV2 = () => {
  const arr: INewMenuItem[] = [];

  if (CONFIG.DEV_PAGES) {
    arr.push({
      name: 'Режим разработки',
      icon: 'code',
      href: '/v2/dev-page',
    });
  }

  return arr;
};

