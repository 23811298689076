import {
  action, computed, makeObservable, observable,
} from 'mobx';

class FeatureFlagsStore {
  constructor() {
    makeObservable(this);
  }

  @observable accessor _ff: IFeatureFlagsBase | null = null;
  @observable accessor _loading = true;

  @action
  setFeatureFlags = (featureFlags: IFeatureFlagsBase) => {
    this._ff = { ...featureFlags };
  };

  @action
  setFeatureFlagsLoading = (loading: boolean) => {
    this._loading = loading;
  };

  @computed
  get ffList(): Partial<IFeatureFlagsBase> {
    return this._ff || {};
  }

  @computed
  get ffLoading() {
    return this._loading;
  }
}


export const featureFlagsStore = new FeatureFlagsStore();
