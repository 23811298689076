import {
  action, computed, makeObservable, observable,
} from 'mobx';

import { access } from '../access';

import {
  devFilterMenu, filterV2Adopted, remapMenu,
} from './utils/remapMenu';
import { prepareItems } from '../../../bi/utils/menu';
import { menuV2 } from './consts';
import { IMenuItem, INewMenuItem } from './types.ts';

class WorkspaceStore {
  constructor() {
    makeObservable(this);
  }

  @observable accessor _userName: string | null = null;
  @observable accessor _appMenu: INewMenuItem[] = [];

  @action
  setUserName = (userName: string) => {
    this._userName = userName;
  };

  @action
  loadOldNavigation = async () => {
    const oldPrepareItems = (items: IMenuItem[]): IMenuItem[] => prepareItems({
      items: items as never[],
      services: access.ffStore.ffList.Services as never[],
      reports: access.ffStore.ffList.Reports as never[],
      mice: access.ffStore.ffList.Mice,
      accountant: access.ffStore.ffList.Accountant,
      calculator: access.ffStore.ffList.AirlineTaxSettings,
      vacation: access.ffStore.ffList.Vacation,
    });

    const oldMenu = [
      devFilterMenu,
      filterV2Adopted,
      oldPrepareItems,
    ].reduce(
      (acc, filter) => filter(acc),
      window.Rise.getStore('App').getState().menu as IMenuItem[],
    );

    this._appMenu = [
      ...remapMenu(oldMenu),
      ...menuV2(),
    ];
  };

  @computed
  get userName() {
    return this._userName || '';
  }

  @computed
  get appMenu() {
    return this._appMenu;
  }
}

export const workspaceStore = new WorkspaceStore();
