import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'sw-ui';

import { rootService } from '../../../../core/business';

import { Templates } from './components/Templates';
import { PeriodsSettings } from './components/PeriodsSettings';

import { LABELS, ModalType } from './consts';

import styles from './styles.module.scss';

export const DocumentTemplates = observer(() => {
  const [modal, setModal] = useState<ModalType | null>(null);

  if (!rootService.services.access.ffStore.ffList.RegistryConstructor) return null;

  return (
    <div className={styles.btnContainer}>
      <Button theme='flat' onClick={() => setModal(ModalType.templates)}>
        { LABELS.SETTINGS_NAME }
      </Button>
      <Button theme='flat' onClick={() => setModal(ModalType.periods)}>
        { LABELS.PERIOD_SETTINGS_NAME }
      </Button>
      <Templates isOpen={modal === ModalType.templates} onClose={() => setModal(null)} />
      <PeriodsSettings isOpen={modal === ModalType.periods} onClose={() => setModal(null)} />
    </div>
  );
});
