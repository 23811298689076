export const WIKI_LINK = 'https://docs.google.com/spreadsheets/d/1I52ZOMFvhB3C5ow6-JqH3dN87MHspNR-HMErHgCN2LI/edit?usp=sharing';

export const LABELS = {
  SETTINGS_NAME: ' Настройки шаблонов реестров',
  PERIOD_SETTINGS_NAME: 'Настройки реестров для ЗД',
  VIEW_TITLE: 'Шаблоны реестров',
  OPEN_WIKI: 'Открыть инструкцию',
  UPLOAD_TEMPLATE: 'Загрузить шаблон',
  REMOVE_TEMPLATE: (many = false) => `Удалить шаблон${many ? 'ы' : ''}`,
  COLUMN_NAMES: {
    SAVED: 'Сохраненные реестры',
    DOWNLOAD_PREVIEW: 'Скачать файл с предпросмотром',
    DOWNLOAD_FORM: 'Скачать форму реестра',
  },
};

export enum ModalType {
  templates,
  periods,
}

export const COLUMN_LIST = Object.values(LABELS.COLUMN_NAMES);
