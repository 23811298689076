import React, { ChangeEvent, useRef } from 'react';
import { Button } from 'sw-ui';

import { rootService } from '../../../../../core/business';

import { LABELS } from '../consts';

import styles from '../styles.module.scss';


export const UploadFile = () => {
  const ref = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) rootService.services.company.uploadFile(files[0]);
  };

  return (
    <React.Fragment>
      <input
        type='file'
        multiple={false}
        ref={ref}
        hidden
        onChange={uploadFile}
      />
      <Button
        onClick={handleSelectFile}
        className={styles.upload}
      >
        { LABELS.UPLOAD_TEMPLATE }
      </Button>
    </React.Fragment>

  );
};
