import { observer } from 'mobx-react-lite';
import { Checkbox } from 'sw-ui';

import { rootService } from '../../../../../../core/business';

import DocumentIcon from '../../../../../../../components/documentIcon';
import { InputName } from '../InputName';

import styles from '../../styles.module.scss';
import { IRegistryConstructorTemplate } from '../../../../../../services/company/types';

interface ITemplateRowProps {
  row: IRegistryConstructorTemplate;
  onSelect: (value: string) => void;
  value: string;
}

export const TemplateRow = observer((
  {
    row, onSelect, value,
  }: ITemplateRowProps,
) => {
  const { updateTemplateName, documentTemplates: { loadingField } } = rootService.services.company;

  return (
    <tr>
      <td className={styles.td}>
        <Checkbox
          onChange={onSelect}
          value={value}
        />
      </td>
      <td className={styles.td}>
        <InputName
          onChange={(name) => updateTemplateName(name, row.id)}
          value={row.name}
          loading={loadingField === row.id}
        />
      </td>
      <td className={styles.td}>
        <div className={styles.iconCell}>
          { row.fileTypeStrings.map((format: string) => (
            <DocumentIcon
              key={format}
              type={format}
              onClick={() => rootService.services.company.getFilePreview(row.id, 'xlsx')}
            />
          )) }

        </div>
      </td>
      <td className={styles.td}>
        <div className={styles.iconCell}>
          <DocumentIcon type='xlsx' />
        </div>
      </td>
    </tr>
  );
});
