import { ROUTE_LIST } from '../pages';
import { history } from './history';

const pathNames = ROUTE_LIST.map(({ path }) => path);

export const versions = {
  old: 0,
  v2: 1,
};

export const pathV2Includes = (
  path = history.location.pathname,
) => (pathNames.includes(path) ? versions.v2 : versions.old);
