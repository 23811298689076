import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button, Dialog, Loading,
} from 'sw-ui';

import { rootService } from '../../../../../core/business';

import { Table } from './Table';
import { UploadFile } from './UploadFile';
import { TemplateRow } from './Table/TemplateRow';

import { openNewWindow } from '../../../../../utils/redirect';

import { LABELS, WIKI_LINK } from '../consts';

import styles from '../styles.module.scss';

interface ITemplatesProps {
  isOpen: boolean;
  onClose: (value?: boolean) => void;
}

const Header = () => {
  const handleRedirect = () => {
    openNewWindow(WIKI_LINK);
  };

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        { LABELS.VIEW_TITLE }
      </div>
      <Button
        onClick={handleRedirect}
        theme='second'
        className={styles.headerButton}
      >
        { LABELS.OPEN_WIKI }
      </Button>
    </div>
  );
};


export const Templates = observer(({ isOpen, onClose }: ITemplatesProps) => {
  const {
    loadTemplates,
    removeTemplates,
    documentTemplates: {
      loading, templates, error,
    },
  } = rootService.services.company;

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    loadTemplates();
  }, []);

  if (!isOpen) return null;

  const renderTable = () => (loading
    ? <Loading />
    : (
      <div className={styles.tableContainer}>
        <div className={styles.actions}>
          <div>
            <UploadFile />
          </div>
          <div>
            <Button
              onClick={() => removeTemplates(selected)}
              disabled={selected.length === 0}
              className={styles.remove}
            >
              { LABELS.REMOVE_TEMPLATE(selected.length > 1) }
            </Button>
          </div>
        </div>
        <div className={styles.errorMessage}>
          { error }
        </div>
        <Table
          onSelect={setSelected}
          selected={selected}
          list={templates}
          Row={TemplateRow}
        />
      </div>
    ));

  return (
    <Dialog onClick={onClose} showCloseButton>
      <div className={styles.viewContainer}>
        <Header />
        { renderTable() }
      </div>
    </Dialog>
  );
});
