import {
  MouseEvent, PropsWithChildren, useEffect, useState,
} from 'react';
import { matchPath } from 'react-router-dom';
import { clsx } from 'clsx';

import { openNewWindow, redirectToV2 } from '../../../../utils/redirect';

import styles from './styles.module.scss';

interface ILinkProps {
  onClick?: (link?: string) => void;
  onMiddleClick?: (link?: string) => void;
  href?: string;
  className?: string;
  classNameActive?: string;
}

const match = (path: string) => matchPath(
  { path },
  window.location.pathname,
);

export const Link = ({
  onClick = redirectToV2,
  onMiddleClick = openNewWindow,
  href,
  className,
  classNameActive,
  children,
}: PropsWithChildren<ILinkProps>) => {
  const baseClasses = [styles.link, className];

  const [classes, setClasses] = useState(clsx(baseClasses));

  useEffect(() => {
    const matched = match(String(href));

    setClasses(clsx(
      matched
        ? [...baseClasses, classNameActive]
        : baseClasses,
    ));
  }, [href]);

  const handleRedirect = (
    e: MouseEvent<HTMLSpanElement>,
  ) => {
    switch (e.button) {
      case 0:
        return onClick(href);
      case 1:
        return onMiddleClick(href);
    }
  };

  return (
    <span
      className={classes}
      role='link'
      tabIndex={0}
      onMouseUp={handleRedirect}
      data-href={href}
    >
      { children }
    </span>
  );
};
