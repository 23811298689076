import { To } from 'history';
import { history } from './history';

export const redirectToV2 = (path?: To) => {
  if (path) history.push(path);
};

export const redirectFromOld = redirectToV2;

// example
// <button onClick={ () => redirectFromOld(ROUTE_INFO_V2.experimental.path) }>
//  туда
// </button>

export const redirectToOld = (path: string) => {
  try {
    window.Rise.navigate(path);
  } catch (e) {
    return e;
  }

  history.push(path);

  return null;
};

export const openNewWindow = (
  path: string | URL | undefined,
) => window.open(path, '_blank');

