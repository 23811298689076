import { Route, Routes } from 'react-router-dom';

import { clsx } from 'clsx';

import { DevPage } from './DevPage';

import styles from './styles.module.scss';

export const Header = () => (
  <>
    <div className={clsx(styles.container, styles.containerSubstrate)}>
      <Routes>
        <Route
          path='/v2/dev-page'
          Component={DevPage}
        />
      </Routes>
    </div>
    <div className={styles.containerSubstrate} />
  </>
);
