export const isRiseCreating = () => typeof window.Rise === 'undefined';

export const riseCreating = async () => {
  if (isRiseCreating()) {
    await new Promise((r) => setTimeout(r, 5));

    return riseCreating();
  }

  return null;
};
