import { services } from '../../services';


class RootService {
  services = services;

  constructor() {
    this.services.workspace.kickOutIfUnknown();
    this.services.access.loadFF();
  }
}

export const rootService = new RootService();

