import React from 'react';
import { Checkbox } from 'sw-ui';

import { COLUMN_LIST } from '../../consts';

import styles from '../../styles.module.scss';

const Columns = () => (
  <React.Fragment>
    { COLUMN_LIST.map((name, index) => (
      <th className={styles.th} key={index}>
        { name }
      </th>
    )) }
  </React.Fragment>
);

const switchArrayValuesBy = (first = [], second, alg = (a, b) => a === b) => {
  const exist = first.filter((fVal) => !alg(fVal, second));

  if (exist.length < first.length) {
    return exist;
  }

  return [...first, second];
};

export const Table = ({
  selected, onSelect, list, Row,
}) => {
  const allSelected = selected.length === list.length;

  const handleAllSelect = () => onSelect(allSelected ? [] : list.map(({ id }) => id));

  const handleSelect = (id: string) => () => onSelect(switchArrayValuesBy(selected, id));

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.th}>
            <Checkbox
              value={allSelected}
              onChange={handleAllSelect}
            />
          </th>
          <Columns />
        </tr>
      </thead>
      <tbody>
        { list.map((item) => (
          <Row
            key={item.id}
            row={item}
            value={selected.includes(item.id)}
            onSelect={handleSelect(item.id)}
          />
        )) }
      </tbody>
    </table>
  );
};
