import { useState } from 'react';
import { Dialog, Select } from 'sw-ui';
import DatePicker from 'react-datetime';

import COMPONENTS from '../../../../../../../bi/constants/components';
import { LABELS } from '../../consts';

import type { Moment } from 'moment';

import styles from '../../styles.module.scss';

interface IPeriodsSettingsProps {
  onClose: (value: boolean) => void;
  isOpen: boolean;
}

export const PeriodsSettings = (
  { onClose, isOpen }: IPeriodsSettingsProps,
) => {
  const st = useState<Moment | string>();

  if (!isOpen) return null;

  return (
    <Dialog onClick={onClose} showCloseButton>
      <div className={styles.viewContainer}>
        <div className={styles.title}>
          { LABELS.PERIOD_SETTINGS_NAME }
        </div>
        <div className={styles.periodForm}>
          <Select
            theme={COMPONENTS.SELECT.THEME.BORDER}
            value={null}
            items={[{ label: 'dsd', value: 'dsd' }]}
            onChange={() => {
            }}
          />
          <div className={styles.dateContainer}>
            <div>
              с
            </div>
            <DatePicker value={st[0]} onChange={st[1]} timeFormat={false} />
          </div>
          <Select
            theme={COMPONENTS.SELECT.THEME.BORDER}
            value={null}
            items={[{ label: 'dsd', value: 'dsd' }]}
            onChange={() => {
            }}
          />
        </div>
        <div>
          <div>
            Настроенные реестры
          </div>
          <div className={styles.periodsTable}>
            <div className={styles.periodsRow}>
              <div>
                реестр
              </div>
              <div>
                с date
              </div>
              <Select
                theme={COMPONENTS.SELECT.THEME.BORDER}
                value={null}
                items={[{ label: 'dsd', value: 'dsd' }]}
                onChange={() => {
                }}
              />
              <div>
                delete
              </div>
            </div>
            <div className={styles.periodsRow}>
              <div>
                реестр
              </div>
              <div>
                с date
              </div>
              <Select
                theme={COMPONENTS.SELECT.THEME.BORDER}
                value={null}
                items={[{ label: 'dsd', value: 'dsd' }]}
                onChange={() => {
                }}
              />
              <div>
                delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
