import { UUID } from 'node:crypto';
import { Response } from 'superagent';

import ApiInstance from '../../../bi/api';

import { documentTemplates } from './documentTemplates';

import { getCompanyFromParams } from './utils/query';

export class Company {
  documentTemplates = documentTemplates;

  loadTemplatesRaw = async () => {
    const templates = await ApiInstance.Company.loadListRegistryConstructor(
      getCompanyFromParams(),
    );

    this.documentTemplates.setTemplates(templates);
  };

  uploadFile = async (file: File) => {
    this.documentTemplates.setLoading(true);
    try {
      await ApiInstance.Company.uploadRegistryConstructor(
        file,
        getCompanyFromParams(),
      );
      await this.loadTemplatesRaw();
    } catch (e: unknown) {
      if (e instanceof Response) {
        this.documentTemplates.setLoading(false);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    this.documentTemplates.setLoading(false);

    return null;
  };

  getFilePreview = async (rId: UUID, format: string) => {
    try {
      await ApiInstance.Company.getPreviewRegistryConstructor(
        rId,
        format,
      );

      return null;
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }
  };


  loadTemplates = async () => {
    this.documentTemplates.setLoading(true);
    try {
      await this.loadTemplatesRaw();
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoading(false);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    return this.documentTemplates.setLoading(false);
  };

  removeTemplates = async (ids: UUID[]) => {
    this.documentTemplates.setLoading(true);
    try {
      await ApiInstance.Company.removeRegistryConstructor(ids);
      await this.loadTemplatesRaw();
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoading(false);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    return this.documentTemplates.setLoading(false);
  };


  updateTemplateName = async (name: string, id: UUID) => {
    this.documentTemplates.setLoadingField(id);
    try {
      await ApiInstance.Company.updateRegistryConstructorName(
        id, name,
      );
      await this.loadTemplatesRaw();
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoadingField(id);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    return this.documentTemplates.setLoadingField(null);
  };
}

export const company = new Company();
