import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DotLoading, Input } from 'sw-ui';

import styles from '../styles.module.scss';

export const InputName = ({
  value, onChange, loading,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const setNewValue = () => {
    onChange(localValue);
    setIsEdit(false);
  };

  const renderContent = () => (isEdit
    ? (
      <React.Fragment>
        <Input
          placeholder='file name'
          value={localValue}
          onChange={setLocalValue}
          className={styles.inputNameInput}
        />
        <button
          className={styles.btnIcon}
          onClick={setNewValue}
        >
          <i className='material-icons'>check</i>
        </button>
        <button
          className={styles.btnIcon}
          onClick={() => setIsEdit(false)}
        >
          <i className='material-icons'>close_small</i>
        </button>
      </React.Fragment>
    )
    : (
      <React.Fragment>
        <div>
          { value }
        </div>
        <button
          className={styles.btnIcon}
          onClick={() => setIsEdit(true)}
        >
          <i className='material-icons'>mode_edit</i>
        </button>
      </React.Fragment>
    ));

  const renderWrap = loading ? () => <DotLoading /> : renderContent;

  return (
    <div className={styles.inputName}>
      { renderWrap() }
    </div>
  );
};


InputName.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

InputName.defaultProps = { loading: false };
